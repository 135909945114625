import React from 'react';
import classes from './LoadingCube.module.scss';
const LoadingCube = ({
                         text
                     }) => {

    return (
        <div className={classes.loadingWrapper}>
            <div className={classes.cube}>
                <div className={classes.sides}>
                    <span className={classes.top}><img src="/assets/images/side1.png" alt="s" /></span>
                    <span className={classes.side}><img src="/assets/images/side1.png" alt="s" /></span>
                    <span className={classes.side}><img src="/assets/images/side2.png" alt="s" /></span>
                    <span className={classes.side}><img src="/assets/images/side1.png" alt="s" /></span>
                    <span className={classes.side}><img src="/assets/images/side2.png" alt="s" /></span>
                    <span className={classes.shadow}></span>
                    <span className={classes.side}>{text}</span>
                    <span className={classes.side}>{text}</span>
                    <span className={classes.side}>{text}</span>
                </div>
            </div>

        </div>
    );
};

export default LoadingCube;
