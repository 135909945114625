import React from 'react';
import classes from './App.module.scss';
import TokenDrawer from 'App/components/TokenDrawer';
import useDB from 'App/hooks/useDB';
import Workflow from 'App/components/Workflow';
import { DataContextProvider } from 'App/contexts/DataContext/DataContext';
import Control from 'App/components/Control';
import { SocketProvider } from 'App/contexts/SocketContext/SocketContext';

const App = () => {

    const {
        db,
        loading,
    } = useDB();


    return <SocketProvider>
        <div className={classes.root}>
            <TokenDrawer/>
            <div className={classes.pageBody}>
                {
                    db?.requests?.map((request) => {
                        return <DataContextProvider
                            key={request.url + request.method}
                            request={request}>
                            <Workflow
                                enumGroups={db.enumGroups}
                            />
                        </DataContextProvider>
                    })
                }
                <Control/>
            </div>
        </div>
    </SocketProvider>;
};

export default App;
