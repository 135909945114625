import { createContext, useState, useEffect, useCallback, Dispatch, SetStateAction } from 'react';
import { RandomizerWrapperProps } from 'App/components/Workflow/Workflow';
import { CustomRequest } from 'App/hooks/useDB/useDB';


type ContextState = {
    requestBody?: any,
    setRequestBody?: (requestBody: any) => void,
    templateData?: any,
    setTemplateData?: (templateData: any) => void,
    randomizerData?: RandomizerWrapperProps,
    setRandomizerData?: Dispatch<SetStateAction<RandomizerWrapperProps>>,
    request: CustomRequest,
}
export const DataContext = createContext<ContextState>({
    requestBody: {},
    setRequestBody: () => {
    },
    templateData: {},
    setTemplateData: () => {
    },
    randomizerData: {
        randomizerParams: {},
        lastUpdated: 0,
    },
    setRandomizerData: () => {
    },
    request: null,
});

export const DataContextProvider = ({ children, request }) => {
    const [requestBody, setRequestBody] = useState(request.initialBody || {});
    const [templateData, setTemplateData] = useState(request.initialUrlTemplateParams);
    const [randomizerData, setRandomizerData] = useState<RandomizerWrapperProps>({
        randomizerParams: request.randomizerParams || {},
        lastUpdated: 0,
    });

    return <DataContext.Provider value={{
        requestBody,
        setRequestBody,
        templateData,
        setTemplateData,
        randomizerData,
        setRandomizerData,
        request,
    }}>
        {children}
    </DataContext.Provider>;
};
