import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App/App';
import './index.css';
import { AppLocalDataProvider } from 'App/contexts/AppLocalDataContext/AppLocalDataContext';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <AppLocalDataProvider>
            <App/>
        </AppLocalDataProvider>
    </React.StrictMode>
);

