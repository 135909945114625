import { useState, useCallback, useMemo } from 'react';
import axios from 'axios';
import { CustomRequest } from 'App/hooks/useDB/useDB';

type Props = {
    token: string;
    requestConfig?: CustomRequest;
    requestBody?: object;
    pathParams?: object;
    baseURL?: string;
}
export type RequestState = 'IDLE' | 'PENDING' | 'SUCCESS' | 'ERROR';
export const getQueries = (obj: object) => {
    if (!obj) {
        return '';
    }

    const queryArr = Object.keys(obj).reduce((queryArrObj, key) => {
        return [
            ...queryArrObj,
            `${key}=${Array.isArray(obj[key]) ? obj[key].join(',') : obj[key]}`,
        ];
    }, []);

    return `?${queryArr.join('&')}`;
};

export const queryParamsString = (body, config: CustomRequest) => {
    if(!body || !config?.body) return '';
    const queryBody = Object
        .keys(config.body).filter((key) => config.body[key].isQueryParam);
    if(queryBody?.length === 0) return '';
    const queryParams = queryBody.reduce((acc, key) => {
        return {
            ...acc,
            [key]: body[key],
        };
    }, {});
    return getQueries(queryParams);
}
export const getBodyData = (body, config: CustomRequest) => {
    if(!body || !config?.body) return {};
    return Object
        .keys(config.body).filter((key) => !config.body[key].isQueryParam).reduce((acc, key) => {
            return {
                ...acc,
                [key]: body[key],
            };
        }, {});
}
const useAxiosClient = ({
                            token,
                            requestConfig = null,
                            requestBody,
                            pathParams,
                            baseURL = 'https://skycelldev.servicebus.windows.net',
                        }: Props) => {
    const [state, setState] = useState<RequestState>('IDLE');
    const [error, setError] = useState(null);
    const [response, setResponse] = useState(null);
    const [clientEnabled, setClientEnabled] = useState(true);
    const client = useMemo(() => axios.create({
        baseURL,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': '*/*',
            'Content-Type': 'application/json;charset=utf-8',
        },
    }), [token]);

    const toggleClient = useCallback(() => {
        setClientEnabled((prev) => !prev);
    }, []);
    const url = useMemo<string>(() => {
        let url = requestConfig?.url || '';
        if (pathParams) {
            Object.keys(pathParams).forEach((key) => {
                url = url.replace(`:${key}`, pathParams[key]?.toString());
            });
        }
        return url + queryParamsString(requestBody, requestConfig);
    }, [requestConfig, pathParams]);
    const sendRequest = useCallback(async () => {
        if (requestConfig) {
            setState('PENDING');
            return await client(url, {
                method: requestConfig.method,
                ...(requestBody ? {
                    data: getBodyData(requestBody, requestConfig),
                } : {}),
            })
                .then((response) => {
                    setState('SUCCESS');
                    const formedResponse = {
                        ...(response?.data || {})
                    }
                    if(requestConfig.returnBack) {
                        Object.keys(requestConfig.returnBack).forEach(key => {
                            const v = requestConfig.returnBack[key];
                            if(v === 'as-is') {
                                formedResponse[key] = requestBody[key];
                            } else if(v.template) {
                                let template = v.template;
                                Object.keys(requestBody).forEach(k => {
                                    template = template.replaceAll(`#${k}`, requestBody[k]);
                                })
                                formedResponse[key] = template;
                            }
                        })
                    }
                    setResponse({
                        ...response,
                        data: formedResponse,
                    });
                })
                .catch((error) => {
                    setState('ERROR');
                    setError(error);
                });

        }

    }, [client, url, requestBody, requestConfig]);
    return {
        client,
        state: clientEnabled ? state : 'IDLE',
        error,
        response,
        sendRequest,
        toggleClient,
    };
};

export default useAxiosClient;
