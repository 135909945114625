import React, { useState } from 'react';
import { AutoComplete, Badge } from 'antd';
import classes from '../Editor.module.scss';

type Props = {
    enumArrayValue: any[],
    enumData?: (string | number)[],
    onChange: (value: any[]) => void,
    repeatable?: boolean,
    column?: boolean,
    disabled?: boolean,
}
const EnumArrayField = ({
                            enumArrayValue,
                            enumData,
                            onChange,
                            column = false,
                            repeatable = false,
                            disabled = false,
                        }: Props) => {

    const [input, setInput] = useState('');
    return <div className={[classes.enumArrayField, column && classes.column].join(' ')}>
        <div className={classes.badgeList}>
            {
                enumArrayValue.map((option, i) => <div className={classes.badge}
                                                       onClick={() => onChange(enumArrayValue.filter((it, innerI) => i !== innerI))}
                >
                    {option}
                </div>)
            }
        </div>
        <AutoComplete
            disabled={disabled}
            style={{ width: 200 }}
            value={input}
            onChange={(val) => setInput(val)}
            options={
                (enumData || [])
                    .filter(it => repeatable || !enumArrayValue.includes(it))
                    .map((option) => ({ value: option }))
            }
            filterOption={(inputValue, option) => String(option.value).includes(inputValue)}
            onSelect={(val) => {
                setInput('');
                onChange([...enumArrayValue, val])
            }}
        />
    </div>;
};

export default EnumArrayField;
