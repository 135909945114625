import React from 'react';
import { bodyField } from 'App/hooks/useDB/useDB';
import { Input, Select, Cascader, AutoComplete } from 'antd';
import { Option } from 'antd/es/mentions';
import EnumArrayField from 'App/components/Workflow/Editor/EnumArrayField';
import classes from '../Editor.module.scss';

type Props = {
    fieldData: bodyField,
    onChange: (value: any) => void,
    value: string | number | boolean | (string | number | boolean)[],
    enumData?: (string | number)[],
    disabled?: boolean,
}
const FieldEdit = ({
                       fieldData,
                       onChange,
                       enumData,
                       value,
                       disabled,
                   }: Props) => {
    const {
        type,
        constraints,
    } = fieldData;

    switch (type) {
        case 'string':
            const stringValue = value as string;

            return <Input
                disabled={disabled}
                className={[
                    classes.fieldInput
                ].join(' ')}
                value={stringValue}
                onChange={(e) => onChange(e.target.value)}
            />;
        case 'number':
            const numberValue = value as number;

            return <Input
                disabled={disabled}
                className={[
                    classes.fieldInput
                ].join(' ')}
                type={'number'}
                min={constraints?.min}
                max={constraints?.max}
                value={numberValue}
                onChange={(e) => {
                    const val = Math.min(Math.max(Number(e.target.value), constraints?.min || Number.MIN_SAFE_INTEGER),
                        constraints?.max || Number.MAX_SAFE_INTEGER);

                    onChange(val);
                }}
            />;
        case 'boolean':

            return <Select
                disabled={disabled}
                className={[
                    classes.fieldInput
                ].join(' ')}
                value={value ? 'true' : 'false'}
                onChange={(val) => onChange(val === 'true')}
            >
                <Option value="true">True</Option>
                <option value="false">False</option>
            </Select>;
        case 'enum':
            const enumValue = value as string;
            return <Select
                disabled={disabled}
                className={[
                    classes.fieldInput
                ].join(' ')}
                value={enumValue}
                onChange={(val) => onChange(val)}
            >
                {
                    enumData.map((option) => <option value={option}>{option}</option>)
                }
            </Select>
        case 'enumArray':
            const enumArrayValue = value as (string | number | boolean)[];

            return <EnumArrayField
                disabled={disabled}
                enumArrayValue={enumArrayValue}
                enumData={enumData}
                onChange={onChange}
                repeatable={!constraints?.isSet}
            />
        case 'date':
            const dateValue = value as string;

            return <Input
                disabled={disabled}
                className={[
                    classes.fieldInput
                ].join(' ')}
                value={dateValue}
                onChange={(e) => onChange(e.target.value)}
            />;
        default:
            const defValue = value as string;

            return <Input
                disabled={disabled}
                className={[
                    classes.fieldInput
                ].join(' ')}
                value={defValue}
                onChange={(e) => onChange(e.target.value)}
            />;
    }
};

export default FieldEdit;
