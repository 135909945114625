import { useEffect, useState } from 'react';


export type bodyField = {
    type: 'string' | 'number' | 'boolean' | 'enum' | 'enumArray' | 'array' | 'date';
    enumValues?: (string | number)[];
    enumGroup?: string;
    dateFormat?: string;
    isQueryParam?: boolean;
    constraints?: {
        min?: number;
        max?: number;
        length?: number;
        camelCase?: boolean;
        snakeCase?: boolean;
        kebabCase?: boolean;
        startsWith?: string;
        regex?: string;
        isSet?: boolean;
    }
}

export type bodyObject = {
    [key: string]: bodyField;
}
export type CustomRequest = {
    url: string;
    baseURL: string;
    urlTemplateParams: string[],
    initialUrlTemplateParams: {
        [key: string]: string | number
    },
    body: bodyObject,
    initialBody: {
        [key: string]: string | number
    },
    returnBack: {
        [key: string]: 'as-is' | {
            template: string,
        }
    }
    method: 'GET' | 'POST' | 'PUT' | 'DELETE';
}
export type DB = {
    requests: CustomRequest[];
    enumGroups: {
        [key: string]: (string | number)[],
    }
}

const useDB = () => {
    const [db, setDB] = useState<DB>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        (async () => {
            const result = await fetch('db.json').then((res) => res.json());
            setDB(result);
            setLoading(false);
        })();
    }, []);


    return {
        db,
        loading,
    }
};

export default useDB;
