import { createContext, useState, useEffect, useCallback } from 'react';


type ContextState = {
    token: string;
    setToken: (token: string) => void;
}
export const AppLocalDataContext = createContext<ContextState>({
    token: '',
    setToken: () => {
    },
});

export const AppLocalDataProvider = ({ children }) => {
    const [token, setToken] = useState('');

    const handleSaveToken = useCallback((token: string) => {
        setToken(token);
        localStorage.setItem('token', token);
    }, []);
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setToken(token);
        }
    }, []);
    return <AppLocalDataContext.Provider value={{
        token: token,
        setToken: handleSaveToken,
    }}>
        {children}
    </AppLocalDataContext.Provider>;
};
