import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import { CustomRequest, bodyField } from 'App/hooks/useDB/useDB';
import classes from './Workflow.module.scss';
import Editor from 'App/components/Workflow/Editor';
import { Button, Tooltip, Input } from 'antd';
import useAxiosClient, { queryParamsString } from 'App/hooks/useAxiosClient/useAxiosClient';
import { AppLocalDataContext } from 'App/contexts/AppLocalDataContext/AppLocalDataContext';
import LoadingCube from 'App/components/Workflow/LoadingCube';
import { DataContext } from 'App/contexts/DataContext/DataContext';

type Props = {
    enumGroups: {
        [key: string]: (string | number)[]
    }
}

function createDynamicPath(basePath, params, setParams) {

    return basePath.split('/').map((pathPart) => {
        if (pathPart.startsWith(':')) {
            const key = pathPart.slice(1);
            return <Tooltip
                placement="top"
                title={<Input
                    onClick={e => e.stopPropagation()}
                    value={params[key]}
                    onChange={(e) => {
                        setParams(prev => ({
                            ...prev,
                            [key]: e.target.value,
                        }));
                    }}
                />}
            >
                /
                <span style={{ color: 'red' }}>
                {
                    params[key]
                }
            </span>
            </Tooltip>
        }
        return <span>/{pathPart}</span>;
    });
}

export type RandomizerProps = {
    enabled: boolean,
    min: number,
    max: number,
    decimals: number,
    count: number,
    pattern: string,
    exclude: string[],
    lastUpdated: number,
    type: bodyField['type']
}
export type RandomizerWrapperProps = {
    randomizerParams: {
        [key: string]: RandomizerProps,
    },
    lastUpdated: number,
}
const WorkFlow = ({
                      enumGroups,
                  }: Props) => {
    const [open, setOpen] = useState(false);
    const {
        request,
        setRandomizerData,
        templateData,
        randomizerData,
        setTemplateData,
        setRequestBody,
        requestBody,
    } = useContext(DataContext);
    const {
        token,
    } = useContext(AppLocalDataContext);
    const {
        sendRequest,
        error,
        response,
        state,
        toggleClient,
    } = useAxiosClient({
        token,
        baseURL: request.baseURL,
        requestBody,
        pathParams: templateData,
        requestConfig: request,
    });

    const handleRandomizeAll = useCallback(() => {
        setRandomizerData(prev => {
            const updated = {
                ...prev,
            }
            for (let key in updated.randomizerParams) {
                updated.randomizerParams[key].lastUpdated = Date.now();
            }
            return updated;
        });

    }, []);
    const handleSendRequest = useCallback(() => {
        handleRandomizeAll();
        sendRequest();
    }, [sendRequest]);

    const openState = useMemo(() => {
        return open || (request.body && request.method === 'GET');
    }, [open, state, request]);
    return <div className={classes.requestWrapper}>
        <div className={classes.workflowWrapper}>
            <div className={classes.workflow}>
                <div
                    onClick={() => {
                        setOpen(prev => !prev)
                    }}
                    className={classes.workflowName}
                >
                    <span className={classes.requestMethod}>
                        ({request.method})
                    </span>
                    <span style={{
                        wordBreak: 'break-word',
                    }}>
                          {
                              createDynamicPath(request.url, templateData, setTemplateData)
                          }
                        {
                            queryParamsString(requestBody, request)
                        }
                    </span>
                </div>
                <div className={classes.workflowActionButtons}>
                    <Button
                        onClick={handleSendRequest}
                        className={classes.runButton}
                    >
                        RUN
                    </Button>
                    {
                        Object.keys(randomizerData.randomizerParams).length > 0 && <Button
                            onClick={handleRandomizeAll}
                            className={classes.randomizeButton}
                        >
                            RANDOMIZE ALL
                        </Button>
                    }
                </div>
            </div>
            {
                open && <div className={classes.jsonWrapper}>
                    <Editor
                        request={request}
                        body={requestBody}
                        setBody={setRequestBody}
                        enumGroups={enumGroups}
                        randomizerData={randomizerData}
                        setRandomizerData={setRandomizerData}
                    />
                </div>
            }

        </div>
        {
            !openState && <div style={{ width: '50%' }}/>
        }
        {
            openState && <div className={[classes.console, state === 'SUCCESS' && classes.greenConsole].join(' ')}>
                <div className={classes.title}>Console</div>
                {
                    state === 'PENDING' && <div>
                        <LoadingCube text={'LOADING'}/>
                    </div>
                }
                <div className={classes.codeWrapper}>
                    {
                        state === 'SUCCESS' && (
                            <>
                                <LoadingCube text="Success!"/>
                                <span className={classes.status}>Status: {response?.status}</span>
                            </>
                        )
                    }
                    {
                        state === 'ERROR' && <div className={classes.errorStatus}>
                            {
                                error?.response?.status ? `Response status: ${error.response.status}`
                                    : 'No allowed response status'
                            }
                        </div>
                    }
                    {
                        state === 'ERROR' && <div className={classes.errorJSON}>
                            {
                                JSON.stringify(error, null, 2)
                            }
                        </div>
                    }
                    {
                        state === 'SUCCESS' && response?.status < 400 && (
                            <span className={classes.response}>
                                {
                                    response.data ? JSON.stringify(response.data, null, 2) : 'No Response body'
                                }
                            </span>
                        )
                    }

                </div>
            </div>
        }

    </div>;
};

export default WorkFlow;
