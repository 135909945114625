import { createContext, useMemo, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { generateRandomString } from 'App/components/Workflow/Editor/Randomizer/stringTool';

type ContextState = {
    uid: string,
    messages: {
        [key: string]: any,
    }[],
}
export const SocketContext = createContext<ContextState>({
    uid: null,
    messages: [],
});

export const SocketProvider = ({ children }) => {

    const userId = useMemo(() => {
        const userId = localStorage.getItem('userId');
        if (userId) {
            return userId
        } else {
            const newUserId = generateRandomString('....................');
            localStorage.setItem('userId', newUserId);
            return newUserId;
        }
    }, []);
    const [messages, setMessages] = useState([]);
    const socket = useMemo(() => {
            // @ts-ignore
            return io.connect('http://localhost:5000', {
                query: { id: userId }
            });
    }, [userId]);

    useEffect(() => {
        socket.off('receiveRequest');
        socket.on('receiveRequest', (data) => {
            if(data.id === userId) {
                setMessages(prev => [...prev, data]);
            }
        })
    }, [socket, userId])

    const uid = useMemo(() => socket?.io?.opts?.query?.id, [socket])

    return <SocketContext.Provider value={{
        uid,
        messages,
    }}>
        {children}
    </SocketContext.Provider>;
};
