import React, { useState, Dispatch, SetStateAction, useCallback, useRef, useMemo, useEffect } from 'react';
import { CustomRequest } from 'App/hooks/useDB/useDB';
import classes from './Editor.module.scss';
import FieldEdit from 'App/components/Workflow/Editor/FieldEdit';
import { Button, Tooltip } from 'antd';
import { RandomizerWrapperProps } from 'App/components/Workflow/Workflow';
import Randomizer from 'App/components/Workflow/Editor/Randomizer';
import RandomizerFieldUpdater from 'App/components/Workflow/Editor/Randomizer/RandomizerFieldUpdater';

type Props = {
    request: CustomRequest,
    body: {
        [key: string]: string | number
    },
    setBody: Dispatch<SetStateAction<any>>,
    enumGroups: {
        [key: string]: (string | number)[]
    },
    randomizerData: RandomizerWrapperProps,
    setRandomizerData: Dispatch<SetStateAction<RandomizerWrapperProps>>
}

const Editor = ({
                    request,
                    enumGroups,
                    body,
                    setBody,
                    randomizerData,
                    setRandomizerData,
                }: Props) => {
    const {
        body: bodyData
    } = request;
    const [clipboardViewer, setClipboardViewer] = useState({
        open: false,
        clipboardContent: null,
    });

    const handleExportFile = useCallback(() => {
        const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify({
            body,
            randomizerData,
        }));
        const downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.style.display = 'none';
        downloadAnchorNode.setAttribute('href', dataStr);
        downloadAnchorNode.setAttribute('download', `${request.method}_${request.url}_${Date.now()}.json`);
        document.body.appendChild(downloadAnchorNode);
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }, [body, randomizerData, request]);

    const fileInput = useRef(null);

    const handleImportButtonClick = () => {
        fileInput.current.click();
    };

    const handleImportFile = useCallback((event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                try {
                    const result = e.target.result as string;
                    const json = JSON.parse(result);
                    setRandomizerData(json.randomizerData);
                    setBody(json.body);
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                }
            };

            reader.readAsText(file);

        }
    }, [])

    const handlePasteFromClipboardJSON = useCallback(async () => {
        const textResult = await navigator.clipboard.readText()
            .catch(err => {
                console.error('Failed to read clipboard contents: ', err);
            });
        if (!textResult) return;
        try {
            const obj = JSON.parse(textResult);
            if (typeof obj === 'object') {
                setBody(prev => {
                    const newBody = {
                        ...prev,
                    }
                    Object.keys(obj).forEach(key => {
                        if (newBody[key] !== undefined) {
                            newBody[key] = obj[key];
                        }
                    });
                    return newBody;
                });
            }
        } catch (e) {
            alert('WRONG JSON FORMAT')
        }
    }, [])
    useEffect(() => {
        if (clipboardViewer.open) {
            (async () => {
                const textResult = await navigator.clipboard.readText()
                    .catch(err => {
                        console.error('Failed to read clipboard contents: ', err);
                    });
                if (!textResult) return;
                try {
                    const obj = JSON.parse(textResult);
                    if (typeof obj === 'object') {
                        const content = <div className={classes.clipboardWrapper}>{
                            Object.keys(obj).map(key => {
                                const corrupted = body[key] === undefined;
                                return <div className={[classes.clipboard, corrupted && classes.corrupted].join(' ')}>
                                    <span>{key}
                                        {corrupted && <code>Not in set</code>}
                                    </span>
                                    <span>{obj[key]}</span>
                                </div>
                            })
                        }</div>;
                        setClipboardViewer(prev => ({
                            ...prev,
                            clipboardContent: content,
                        }))
                    }
                } catch (e) {
                    console.error('Failed to read clipboard contents: ');
                    setClipboardViewer(prev => ({
                        ...prev,
                        clipboardContent: 'Make sure that you\'ve copied a valid JSON object',
                    }))
                }

            })()
        } else {
            setClipboardViewer(prev => ({
                ...prev,
                clipboardContent: null,
            }))
        }
    }, [clipboardViewer.open])

    return <div className={classes.editor}>
        <div
            className={classes.buttonsWrapper}
        >
            <Button className={classes.viewEditButton} onClick={handleExportFile}>
                Export file
            </Button>
            <Button className={classes.viewEditButton} onClick={handleImportButtonClick}>
                Import file
                <input
                    type="file"
                    ref={fileInput}
                    style={{ display: 'none' }}
                    onChange={handleImportFile}
                    accept={'.json'}
                />
            </Button>
            <Tooltip open={clipboardViewer.open && clipboardViewer.clipboardContent}
                     title={clipboardViewer.clipboardContent}>
                <Button className={classes.viewEditButton} onClick={handlePasteFromClipboardJSON}
                        onMouseEnter={() => setClipboardViewer(prev => ({
                            ...prev,
                            open: true,
                        }))}
                        onMouseLeave={() => setClipboardViewer(prev => ({
                            ...prev,
                            open: false,
                        }))}
                >
                    Paste from Clipboard
                </Button>
            </Tooltip>
        </div>
        {
            bodyData && Object.keys(bodyData)
                .sort((a, b) => bodyData[b].isQueryParam ? -1 : 1)
                .map((field, index, fields) => {
                    return <>
                        {
                            !bodyData?.[fields?.[index - 1]]?.isQueryParam && bodyData?.[fields?.[index]]?.isQueryParam &&
                            <div>
                                <br/>
                                <h2>Path params</h2>
                            </div>
                        }

                        <div
                            className={classes.field}
                            key={`${request.url}${request.method}${field}`}
                        >
                            <div className={classes.fieldName}>
                                {field}
                            </div>
                            :
                            <div className={[classes.fieldValue, classes.fieldValueEdited].join(' ')}>
                                {
                                        <Tooltip
                                            autoAdjustOverflow={true}
                                            placement="right"
                                            destroyTooltipOnHide={false}
                                            title={
                                                <>
                                                    <Randomizer
                                                        key={`${request.url}${request.method}${field}`}
                                                        fieldData={bodyData[field]}
                                                        onChange={(currentParams) => setRandomizerData(
                                                            prev => ({
                                                                ...prev,
                                                                randomizerParams: {
                                                                    ...prev.randomizerParams,
                                                                    [field]: currentParams,
                                                                }
                                                            }))
                                                        }
                                                        enumData={
                                                            bodyData?.[field]?.enumGroup ?
                                                                enumGroups?.[bodyData?.[field]?.enumGroup]
                                                                : bodyData?.[field]?.enumValues
                                                        }
                                                        randomizerData={randomizerData.randomizerParams[field]}
                                                    />
                                                </>
                                            }
                                        >
                                            <div>
                                                <RandomizerFieldUpdater
                                                    fieldData={bodyData[field]}
                                                    enumData={
                                                        bodyData?.[field]?.enumGroup ?
                                                            enumGroups?.[bodyData?.[field]?.enumGroup]
                                                            : bodyData?.[field]?.enumValues
                                                    }
                                                    randomizerData={randomizerData.randomizerParams[field]}
                                                    updateField={(value) => setBody(
                                                        prev => ({
                                                            ...prev,
                                                            [field]: value
                                                        }))
                                                    }
                                                />
                                                <FieldEdit
                                                    value={body[field]}
                                                    disabled={randomizerData?.randomizerParams[field]?.enabled}
                                                    enumData={
                                                        bodyData?.[field]?.enumGroup ?
                                                            enumGroups?.[bodyData?.[field]?.enumGroup]
                                                            : bodyData?.[field]?.enumValues
                                                    }
                                                    fieldData={bodyData[field]}
                                                    onChange={(value) => setBody(
                                                        prev => ({
                                                            ...prev,
                                                            [field]: value
                                                        }))
                                                    }
                                                />
                                            </div>
                                        </Tooltip>
                                }
                            </div>
                        </div>
                    </>
                })
        }
    </div>;
};

export default Editor;
