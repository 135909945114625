import React, { useContext } from 'react';
import classes from './Control.module.scss'
import { SocketContext } from 'App/contexts/SocketContext/SocketContext';

type Props = {
    defaultProp?: string,
}
const Control = ({}: Props) => {
    const {
        uid,
    } = useContext(SocketContext)
    return <div className={classes.root} style={{
        height: '200px'
    }}>
        {uid}
    </div>;
};

export default Control;
