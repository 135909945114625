import React, { useContext, useState, useEffect, useCallback } from 'react';
import { DownOutlined, RedoOutlined } from '@ant-design/icons';
import classes from './TokenDrawer.module.scss';
import TextArea from 'antd/es/input/TextArea';
import { AppLocalDataContext } from 'App/contexts/AppLocalDataContext/AppLocalDataContext';
import { Button } from 'antd';

const TokenDrawer = () => {
    const {
        setToken,
        token,
    } = useContext(AppLocalDataContext);
    const [isOpen, setIsOpen] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const cycleSuccess = useCallback(() => {
        setIsSuccess(true);
        setTimeout(() => {
            setIsSuccess(false);
        }, 500);
    }, []);

    const [input, setInput] = useState('');

    useEffect(() => {
        setInput(token);
    }, [token])
    return <div
        className={[classes.box, isOpen && classes.open].join(' ')}
        onMouseLeave={() => setIsOpen(false)}
        onMouseEnter={() => setIsOpen(true)}
    >
        <TextArea
            className={classes.input}
            value={input}
            onInput={(e) => {
                const target = e.target as HTMLTextAreaElement;
                setInput(target.value.replaceAll('Bearer', '').trim());
            }}
            rows={4}
            placeholder="Token"
        />

        <Button
            onClick={isSuccess ? null : () => {
                cycleSuccess();
                setToken(input);
            }}
            size="small"
            rootClassName={[classes.button, isSuccess && isOpen && classes.success].join(' ')}
        >
            {
                isOpen ? <RedoOutlined/> : <DownOutlined/>
            }
            {
                isOpen && !isSuccess && 'Refresh'
            }
            {
                !isOpen && !isSuccess && 'Token'
            }
            {
                isOpen && isSuccess && 'Success'
            }
        </Button>
    </div>
};

export default TokenDrawer;
