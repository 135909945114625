import React, { useEffect } from 'react';
import { bodyField } from 'App/hooks/useDB/useDB';
import { RandomizerProps } from 'App/components/Workflow/Workflow';
import {
    generateRandomString,
    generateRandomNumber,
    getRandomItem, getRandomItemsSet, getRandomItemsArray
} from 'App/components/Workflow/Editor/Randomizer/stringTool';

type Props = {
    fieldData: bodyField,
    enumData?: (string | number)[],
    randomizerData: RandomizerProps,
    updateField: (value: any) => void,
}
const RandomizerFieldUpdater = ({
                                    fieldData,
                                    enumData,
                                    randomizerData,
                                    updateField,
                                }: Props) => {


    useEffect(() => {
        if (randomizerData?.enabled) {
            switch (fieldData.type) {
                case 'string':
                    if (randomizerData?.pattern) {
                        updateField(generateRandomString(randomizerData?.pattern));
                    }
                    break;
                case 'number':
                    updateField(generateRandomNumber(
                        randomizerData?.min || (fieldData?.constraints?.min === null ? Number.MIN_SAFE_INTEGER : 0),
                        randomizerData?.max || (fieldData?.constraints?.max === null ? Number.MAX_SAFE_INTEGER : 0),
                        randomizerData?.decimals || 0
                    ));
                    break;
                case 'boolean':
                    updateField(Math.random() > 0.5);
                    break;
                case 'enum':
                    updateField(getRandomItem(enumData || [], randomizerData?.exclude || []));
                    break;
                case 'enumArray':
                    if (fieldData?.constraints?.isSet) {
                        updateField(getRandomItemsSet(enumData || [], randomizerData?.exclude || [], randomizerData?.count || 1));
                    } else {
                        updateField(getRandomItemsArray(enumData || [], randomizerData?.exclude || [], randomizerData?.count || 1));
                    }
                    break;
            }
        }

    }, [randomizerData, randomizerData?.lastUpdated, fieldData, enumData])
    return <></>;
};

export default RandomizerFieldUpdater;
