export const generateRandomString = (pattern = '') => {
    const randomLowerCaseLetter = () => String.fromCharCode(Math.floor(Math.random() * 26) + 97);
    const randomUpperCaseLetter = () => String.fromCharCode(Math.floor(Math.random() * 26) + 65);
    const randomLetter = () => Math.random() < 0.5 ? randomLowerCaseLetter() : randomUpperCaseLetter();
    const randomDigit = () => Math.floor(Math.random() * 10).toString();
    const randomAlphanumeric = () => {
        const choice = Math.floor(Math.random() * 3);
        if (choice === 0) return randomLowerCaseLetter();
        if (choice === 1) return randomUpperCaseLetter();
        return randomDigit();
    };
    const randomNumberInRange = (range) => {
        const [min, max] = range.split('.').map(Number);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    return pattern.split(/(\{[0-9]+\.[0-9]+\}|\*|\-|_|\.|=)/).map(segment => {
        if (segment.startsWith('{') && segment.endsWith('}')) {
            return randomNumberInRange(segment.slice(1, -1));
        }
        switch (segment) {
            case '_':
                return randomLowerCaseLetter();
            case '-':
                return randomUpperCaseLetter();
            case '=':
                return randomLetter();
            case '*':
                return randomDigit();
            case '.':
                return randomAlphanumeric();
            default:
                return segment;
        }
    }).join('');
};

export const generateRandomNumber = (min, max, decimals) => {
    const randomNum = Math.random() * (max - min) + min;
    return +randomNum.toFixed(decimals);
};

export const getRandomItem = (arr, except = []) => {
    const exceptSet = new Set(except);

    const filteredArr = arr.filter(item => !exceptSet.has(item));


    const randomIndex = Math.floor(Math.random() * filteredArr.length);

    return filteredArr[randomIndex];
};

export const getRandomItemsSet = (arr, except = [], count = 1) => {
    const exceptSet = new Set(except);
    const filteredArr = arr.filter(item => !exceptSet.has(item));

    for (let i = filteredArr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [filteredArr[i], filteredArr[j]] = [filteredArr[j], filteredArr[i]];
    }

    return filteredArr.slice(0, count);
};
export const getRandomItemsArray = (arr, except = [], count = 1) => {
    const exceptSet = new Set(except);
    const filteredArr = arr.filter(item => !exceptSet.has(item));
    const result = [];

    for (let i = 0; i < count; i++) {
        if (filteredArr.length === 0) {
            return [];
        }
        const randomIndex = Math.floor(Math.random() * filteredArr.length);
        result.push(filteredArr[randomIndex]);
    }

    return result;
};


