import React, { useCallback, useState } from 'react';
import { bodyField } from 'App/hooks/useDB/useDB';
import { RandomizerProps } from 'App/components/Workflow/Workflow';
import classes from '../Editor.module.scss';
import { Button, Input } from 'antd';
import EnumArrayField from 'App/components/Workflow/Editor/EnumArrayField';

type Props = {
    fieldData: bodyField,
    onChange: (value: any) => void,
    randomizerData: RandomizerProps,
    enumData?: (string | number)[],
}
const Randomizer = ({
                        fieldData,
                        onChange,
                        randomizerData,
                        enumData,
                    }: Props) => {

    const [patternHint, setPatternHint] = useState(false);
    const handleRandomizerToggle = useCallback(() => {
        onChange({
            ...(randomizerData || {}),
            enabled: !randomizerData?.enabled,
        });
    }, [randomizerData]);

    return <div className={classes.randomizer}>
        <div className={classes.randomizerRow}>
            <Button
                onClick={handleRandomizerToggle}
                className={[
                    classes.randomizerButton,
                    randomizerData?.enabled && classes.enabled
                ].join(' ')}>
                {randomizerData?.enabled ? 'Randomizer Enabled' : 'Randomizer Disabled'}
            </Button>
        </div>
        {
            randomizerData?.enabled && fieldData.type === 'string' && <div className={classes.randomizerRow}>
                <div>
                    <Button
                        className={classes.randomizerButton}
                        onClick={() => setPatternHint(prev => !prev)}
                    >
                        Toggle hint
                    </Button>
                    <br/>
                    {
                        patternHint && <code style={{
                            width: '200px',
                        }}>
                            <i>*</i> random number <br/>
                            <i>.</i> random letter or number <br/>
                            <i>-</i> random upper case letter <br/>
                            <i>_</i> random lower case letter <br/>
                            <i>=</i> random lower case letter <br/>
                            <i>{'{0.9}'}</i> random number from range <br/>
                            example: {'{4.9}'}*{'{11.126}'}-{'{0.9}'} <br/>

                        </code>
                    }
                    <span>Pattern</span>
                    <Input
                        placeholder="Pattern"
                        value={randomizerData.pattern || ''}
                        onInput={(e) => {
                            const native = e.nativeEvent as any;
                            onChange({
                                ...randomizerData,
                                pattern: native.target.value,
                            });
                        }}
                    />
                </div>
            </div>
        }
        {
            randomizerData?.enabled && fieldData.type === 'number' && <>
                <div className={classes.randomizerRow}>
                    <div>
                        <span>Min</span>
                        <Input
                            placeholder="Min"
                            type="number"
                            value={randomizerData?.min || 0}
                            onInput={(e) => {
                                const native = e.nativeEvent as any;
                                onChange({
                                    ...randomizerData,
                                    min: Math.min(native.target.value, randomizerData?.max || 0),
                                });
                            }}
                        />
                    </div>
                    <div>
                        <span>Max</span>
                        <Input
                            placeholder="Max"
                            type="number"
                            value={randomizerData?.max || 0}
                            onInput={(e) => {
                                const native = e.nativeEvent as any;
                                onChange({
                                    ...randomizerData,
                                    max: Math.max(native.target.value, randomizerData?.min || 0),
                                });
                            }}
                        />
                    </div>
                </div>
                <div className={classes.randomizerRow}>
                    <div>
                        <span>Decimals</span>
                        <Input
                            placeholder="Decimals"
                            type="number"
                            value={randomizerData?.decimals || 0}
                            onInput={(e) => {
                                const native = e.nativeEvent as any;
                                onChange({
                                    ...randomizerData,
                                    decimals: Math.max(0, native.target.value),
                                });
                            }}
                        />
                    </div>
                </div>
            </>
        }
        {
            randomizerData?.enabled && (fieldData.type === 'enum' || fieldData.type === 'enumArray') && <>
                <div>
                    <span>Except</span>
                    <EnumArrayField
                        enumArrayValue={randomizerData?.exclude || []}
                        enumData={enumData}
                        column
                        onChange={(val) => {
                            onChange({
                                ...randomizerData,
                                exclude: val,
                            });
                        }}
                    />
                </div>

                {
                    fieldData.type === 'enumArray' && <div>
                        <span>Count</span>
                        <Input
                            placeholder="Count"
                            type="number"
                            max={fieldData?.constraints?.isSet ?
                                (enumData?.length - (randomizerData?.exclude?.length || 0) || 0)
                                : Number.MAX_SAFE_INTEGER}
                            min={0}
                            value={randomizerData?.count || 0}
                            onInput={(e) => {
                                const native = e.nativeEvent as any;
                                onChange({
                                    ...randomizerData,
                                    count: Math.max(0, native.target.value),
                                });
                            }}
                        />
                    </div>
                }
            </>
        }
    </div>;
};

export default Randomizer;
